import Form from './form';

function App() {
  return (
    <div className="App">
      <header className='flex justify-center text-4xl mb-4'>
        <h2>フレイルチェッカー</h2>
      </header>
      <div className='max-w-lg container mx-auto'>
        <Form></Form>
      </div>
      <footer className='flex justify-center text-xl my-4'>
        <div>
          Copyright:Altplan.co.jp
        </div>
      </footer>
    </div>
  );
}
export default App;
