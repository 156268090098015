import React from 'react';
import { useForm } from 'react-hook-form';

const Form = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => console.log(data);
    console.log(errors);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            { questions.map((item,index)=>
                <div key={index+1} id={index+1}>
                    { errors["q"+(index+1)]?(
                        <>
                        <span className='text-red-600'>入力してください</span>
                        <h2 className='text-2xl	'>{ item.questions }</h2>
                        </>
                    )
                        :(<h2
                            className='text-2xl	'>{ item.questions }</h2>)}
                    <div className='grid grid-cols-3 gap-3 m-10 max-w-md mx-auto'>
                    { item.answers.map((item,idx) =>{
                        return(
                            <div key={"q"+(index+1)+"a"+(idx+1)}
                            >
                                <input {...register("q"+(index+1),{ required: true })}
                                    id = {"q"+(index+1)+"a"+(idx+1)}
                                    type="radio"
                                    value={item}
                                    className ="sr-only peer"
                                />
                                <label
                                    className="text-xl flex p-5 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-blue-300 peer-checked:ring-blue-500 peer-checked:ring-2 peer-checked:border-transparent peer-checked:bg-blue-200"
                                    htmlFor = {"q"+(index+1)+"a"+(idx+1)}
                                >{item}</label>
                            </div>
                        )
                    }) }
                    </div>
                </div>
            )}
        <input
            type="submit"
            className ="sr-only peer"
            id = "sender"
            />
            <label
                htmlFor = "sender"
                className="text-xl flex justify-center p-5 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-blue-300 peer-checked:ring-blue-500 peer-checked:ring-2 peer-checked:border-transparent peer-checked:bg-blue-200"
                >
                チェック
            </label>
        </form>
    );
}


const questions =
  [
    {
      questions : "あなたの現在の健康状態はいかがですか",
      answers : ["よい","まあよい","ふつう","あまりよくない","よくない"]
    },{
      questions : "毎日の生活に満足していますか",
      answers : ["満足","やや満足","やや不満","不満"],
    },{
        questions : "1日3食きちんと食べていますか",
        answers : ["はい","いいえ"],
    },{
        questions : "半年前に比べて固いものが食べにくくなりましたか",
        answers : ["はい","いいえ"],
    },{
        questions : "お茶や汁物等でむせることがありますか",
        answers : ["はい","いいえ"],
    },{
        questions : "6カ月間で2〜3kg以上の体重減少がありましたか",
        answers : ["はい","いいえ"],
    },{
        questions : "以前に比べて歩く速度が遅くなってきたと思いますか",
        answers : ["はい","いいえ"],
    },{
        questions : "この1年間に転んだことがありますか",
        answers : ["はい","いいえ"],
    },{
        questions : "ウォーキング等の運動を週に1回以上していますか",
        answers : ["はい","いいえ"],
    },{
        questions : "周囲の人から「いつも同じことを聞く」などの物忘れがあると言われていますか",
        answers : ["はい","いいえ"],
    },{
        questions : "今日が何月何日か分からない時がありますか",
        answers : ["はい","いいえ"],
    },{
        questions : "あなたはたばこを吸いますか",
        answers : ["吸っている","吸っていない","やめた"],
    },{
        questions : "週に1回以上は外出していますか",
        answers : ["はい","いいえ"],
    },{
        questions : "ふだんから家族や友人と付き合いがありますか",
        answers : ["はい","いいえ"],
    },{
        questions : "体調が悪いときに、身近に相談できる人がいますか",
        answers : ["はい","いいえ"],
    },
  ]

export default Form;
